<script setup lang="ts">
const gallery = [
    { code: 'https://mediahub.woom.com/m/582b036ba3fbf262/3to2-woom1_side_product.jpg', usp_title: 'E-Motor', usp_subtitle: 'Short subtitle about e-motor', usp_text: 'Der superleichte Rahmen aus Aluminium ist das Herzstück des Laufrades und sorgt für unbeschwerten Fahrspaß. Die aufwändig entwickelte, altersgerechte Geometrie mit tiefem Einstieg ermöglicht selbst den Allerkleinsten ein sicheres Aufsteigen und Losfahren.' },
    { code: 'https://mediahub.woom.com/m/1b812b1314bde908/3to2-woom1_perspective_product.jpg', usp_title: '', usp_subtitle: '' },
    { code: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/FBFCB8BA-B0C5-4143-BA9508A5BE96F986/9892FD72-965F-4116-BAB652F2F65378E4/3to2-woom1_brake_detail_scal.jpg', usp_title: 'E-Motor', usp_subtitle: 'Short subtitle about e-motor', usp_text: 'Der superleichte Rahmen aus Aluminium ist das Herzstück des Laufrades und sorgt für unbeschwerten Fahrspaß. Die aufwändig entwickelte, altersgerechte Geometrie mit tiefem Einstieg ermöglicht selbst den Allerkleinsten ein sicheres Aufsteigen und Losfahren.' },
    { code: 'https://mediahub.woom.com/m/7d711e47466bac32/3to2-woom1_stem_detail.jpg', usp_title: 'E-Motor', usp_subtitle: '', usp_text: '' },
    { code: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/4CD33273-C358-455C-9F4959C1A19B8E56/17E87238-3E70-467A-8EE8A936A2A0F322/3to2-woom1_limiter_detail.jpg', usp_title: 'E-Motor', usp_subtitle: 'Short subtitle about e-motor', usp_text: 'Der superleichte Rahmen aus Aluminium ist das Herzstück des Laufrades und sorgt für unbeschwerten Fahrspaß. Die aufwändig entwickelte, altersgerechte Geometrie mit tiefem Einstieg ermöglicht selbst den Allerkleinsten ein sicheres Aufsteigen und Losfahren.' },
    { code: 'https://mediahub.woom.com/m/9bae8b72ad8b6c4/3to2-woom1_wheel_detail.jpg', usp_title: 'E-Motor', usp_subtitle: 'Short subtitle about e-motor', usp_text: 'Der superleichte Rahmen aus Aluminium ist das Herzstück des Laufrades und sorgt für unbeschwerten Fahrspaß. Die aufwändig entwickelte, altersgerechte Geometrie mit tiefem Einstieg ermöglicht selbst den Allerkleinsten ein sicheres Aufsteigen und Losfahren.' },
    { code: 'https://mediahub.woom.com/m/849c1a131350f79/3to2-woom1PLUS_surfboard_detail.jpg', usp_title: 'E-Motor', usp_subtitle: 'Short subtitle about e-motor', usp_text: 'Der superleichte Rahmen aus Aluminium ist das Herzstück des Laufrades und sorgt für unbeschwerten Fahrspaß. Die aufwändig entwickelte, altersgerechte Geometrie mit tiefem Einstieg ermöglicht selbst den Allerkleinsten ein sicheres Aufsteigen und Losfahren.' },
    { code: 'https://mediahub.woom.com/m/582b036ba3fbf262/3to2-woom1_side_product.jpg', usp_title: 'E-Motor', usp_subtitle: 'Short subtitle about e-motor', usp_text: 'Der superleichte Rahmen aus Aluminium ist das Herzstück des Laufrades und sorgt für unbeschwerten Fahrspaß. Die aufwändig entwickelte, altersgerechte Geometrie mit tiefem Einstieg ermöglicht selbst den Allerkleinsten ein sicheres Aufsteigen und Losfahren.' },
    { code: 'https://mediahub.woom.com/m/582b036ba3fbf262/3to2-woom1_side_product.jpg', usp_title: 'E-Motor', usp_subtitle: 'Short subtitle about e-motor', usp_text: 'Der superleichte Rahmen aus Aluminium ist das Herzstück des Laufrades und sorgt für unbeschwerten Fahrspaß. Die aufwändig entwickelte, altersgerechte Geometrie mit tiefem Einstieg ermöglicht selbst den Allerkleinsten ein sicheres Aufsteigen und Losfahren.' },
];

const accordionData = [
    {title: 'Specification', active: false, content: {img: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/8494F7EA-BBB8-44CF-9D45A01195728BE2/4967B360-C1F9-4B29-829819CED9BE966B/3to2-woom_UP5-front_product.jpg', title: 'Woom UP Specification'}  },
    {title: 'Bike geometry',  active: false,content: {img: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/8494F7EA-BBB8-44CF-9D45A01195728BE2/4967B360-C1F9-4B29-829819CED9BE966B/3to2-woom_UP5-front_product.jpg', title: 'Woom UP Geometry'} },
    {title: 'Assembly instructions' ,active: false,content: {img: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/8494F7EA-BBB8-44CF-9D45A01195728BE2/4967B360-C1F9-4B29-829819CED9BE966B/3to2-woom_UP5-front_product.jpg',  title: 'Woom UP Assembly'} },
    {title: 'Features & Highlights', active: false, content: {img: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/8494F7EA-BBB8-44CF-9D45A01195728BE2/4967B360-C1F9-4B29-829819CED9BE966B/3to2-woom_UP5-front_product.jpg',  title: 'Woom UP Hihlights'} },
    {title: 'Accessories', active: false, content: {img: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/8494F7EA-BBB8-44CF-9D45A01195728BE2/4967B360-C1F9-4B29-829819CED9BE966B/3to2-woom_UP5-front_product.jpg',  title: 'Woom UP Accessories'} },
    {title: 'Guarantee information', active: false, content: {img: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/8494F7EA-BBB8-44CF-9D45A01195728BE2/4967B360-C1F9-4B29-829819CED9BE966B/3to2-woom_UP5-front_product.jpg',  title: 'Woom UP Guarantee'} },
    {title: 'Returns',  active: false,content: {img: 'https://d2csxpduxe849s.cloudfront.net/media/07996A5F-31AD-4D03-959900DEDA13913C/8494F7EA-BBB8-44CF-9D45A01195728BE2/4967B360-C1F9-4B29-829819CED9BE966B/3to2-woom_UP5-front_product.jpg',  title: 'Woom UP Returns'} }
]
</script>
<template>
    <div class="pdp-header sm:flex md:grid flex-col product-grid md:py-8 relative">
        <div style="grid-column: 1/4;" class="sticky top-48">
            <mol-gallery :data="gallery"></mol-gallery>
        </div>
        <div class="sticky top-48">
            <div>Product Detail</div>
        </div>
    </div>
    <div><org-accordion 
        :data='accordionData' 
        title='Welcome to the world of flow!' 
        description='The ultralight woom UP 5 represents a well-rounded, playful e-mountain bike for kids aged seven and upwards. With smooth and sensitive support from the motor and child-specific components, it delivers an exceptionally natural feeling when riding together with an extra dose of flow for fun times guaranteed. For surfing the trails or reaching far-flung peaks, the woom UP turns every ride into an adventure.'></org-accordion>
        </div>
</template>
<style>
.product-grid {
  --product-detail-width: 382px;
    grid-template-columns: minmax(24px, 1fr) minmax(0, 192px) Min(792px, calc(100% - 48px)) minmax(0, 192px) minmax(24px, 1fr);
}
</style>