<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Thumbs } from 'swiper/modules';
    import 'swiper/css';

    const props = defineProps({
        /**
         * Array of asset source objects as received from the CMS
         */
        data: {
            type: Array,
            default: null,
        },
        sku: {
            type: String,
            default: '',
        },
        /**
         * Breakpoint for the gallery.
         */
        breakpoint: {
            type: [Number],
            default: 960,
        },
    });

    const galleryArr = ref(props.data);
    const mainSwiper = ref(null);
    const thumbsSwiper = ref(null);
    const infoOpen = ref(false);
    const activeSlideIndex = ref(1);

    const setMainSwiper = (swiper) => {
        mainSwiper.value = swiper;
    };

    const setThumbsSwiper = (swiper) => {
        thumbsSwiper.value = swiper;
    };

    const slidesPerView = computed(() => {
        return galleryArr.value.length >= 3 * 2 ? 3 : Math.floor(galleryArr.value.length / 2);
    });

    const galleryToShow = computed(() => {
        return galleryArr.value.map((value: any) => ({
            ...value,
            src: props.sku + value.code,
        }));
    });

    const openInfo = () => {
        infoOpen.value = !infoOpen.value;
    };

    const changeActiveIndex = () => {
        activeSlideIndex.value = mainSwiper.value.realIndex + 1;
    };
</script>

<template>
    <div class="flex flex-col w-full max-w-full overflow-hidden max-h-[70vh] gap-4 md:gap-10 md:flex-row">
        <swiper
            class="max-w-full flex-1 md:order-2 overflow-hidden"
            :modules="[Thumbs]"
            :thumbs="{ swiper: thumbsSwiper }"
            @swiper="setMainSwiper"
            loop
            @slideChange="changeActiveIndex"
            :slides-per-view="1">
            <swiper-slide v-for="slide in galleryToShow">
                <div class="h-full flex flex-col">
                    <img
                        class="h-full max-h-full max-w-full overflow-hidden mt-auto mb-0 object-contain"
                        :src="slide.code" />

                    <div class="h-12 flex min-h-[3rem]">
                        <div
                            v-if="slide.usp_title"
                            class="bg-gray-100 w-full md:rounded-lg py-3 px-6 flex flex-col items-center justify-between"
                            :class="[infoOpen ? 'absolute bottom-0 h-auto bg-gray-100/90 z-20' : '']">
                            <span class="flex items-center justify-between w-full">
                                <span>
                                    <span class="font-bold">{{ slide.usp_title }}</span>
                                    <span
                                        v-if="slide.usp_subtitle"
                                        :class="[infoOpen ? 'inline-flex' : 'hidden md:inline-flex']"
                                        >-{{ slide.usp_subtitle }}</span
                                    >
                                </span>
                                <a
                                    v-if="slide.usp_text"
                                    class="cursor-pointer text-xs underline"
                                    @click="openInfo">
                                    <span v-if="!infoOpen">More Info</span>
                                    <span v-if="infoOpen">Close</span>
                                </a>
                            </span>
                            <span
                                v-if="slide.usp_text && infoOpen"
                                class="mt-6">
                                {{ slide.usp_text }}
                            </span>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div
                class="flex items-center text-xs px-6 absolute bottom-14 z-10"
                v-if="!infoOpen">
                <svg
                    class="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24">
                    <path
                        d="M15 3.75A2.252 2.252 0 0 1 17.25 6v12A2.252 2.252 0 0 1 15 20.25H9A2.252 2.252 0 0 1 6.75 18V6A2.252 2.252 0 0 1 9 3.75h6zm0 1.5H9a.75.75 0 0 0-.75.75v12c0 .414.336.75.75.75h6a.75.75 0 0 0 .75-.75V6a.75.75 0 0 0-.75-.75zm7.339.748c.879 0 1.608.695 1.66 1.583L24 16.375c-.053.921-.79 1.626-1.679 1.626l-.089-.002L19.5 18a.75.75 0 0 1 0-1.5h2.772l.03.371.001-.37c.109 0 .193-.072.199-.17L22.5 7.625c-.003-.046-.084-.126-.181-.126l-2.802.001H19.5a.75.75 0 0 1 0-1.5h2.772a.892.892 0 0 1 .067-.002zm-20.664.001.093.003L4.5 6a.75.75 0 0 1 0 1.5H1.727c-.135 0-.222.073-.228.169l.001 8.706c.004.048.082.126.181.126l2.802-.001H4.5a.75.75 0 0 1 0 1.5H1.728a1.674 1.674 0 0 1-1.727-1.581L0 7.625c.053-.922.788-1.626 1.675-1.626zm17.827 3.816c.201 0 .389.078.531.22l1.467 1.468a.747.747 0 0 1 0 1.061l-1.47 1.466a.743.743 0 0 1-.53.22.752.752 0 0 1-.53-1.281l.937-.937-.937-.938a.743.743 0 0 1-.219-.53c0-.2.078-.389.22-.53a.744.744 0 0 1 .531-.219zm-15.002 0a.751.751 0 0 1 .531 1.28l-.937.938.936.937a.752.752 0 0 1 0 1.061.746.746 0 0 1-1.06-.001l-1.467-1.467a.75.75 0 0 1 0-1.06l1.467-1.468a.743.743 0 0 1 .53-.22z"
                        fill="#222"
                        fill-rule="evenodd" />
                </svg>
                <span>{{ activeSlideIndex }} </span>/<span>{{ galleryToShow.length }}</span>
            </div>
        </swiper>

        <swiper
            class="!ml-0 md:order-1 max-h-14 !max-w-full !w-fit md:max-h-full md:!w-[165px]"
            :modules="[Thumbs]"
            @swiper="setThumbsSwiper"
            loop
            :spaceBetween="8"
            :slides-per-view="'auto'"
            :looped-slides="slidesPerView"
            direction="horizontal"
            :breakpoints="{ [breakpoint]: { width: 165, direction: 'vertical', spaceBetween: 12 } }">
            <swiper-slide
                v-for="slide in galleryToShow"
                class="!w-[85.5px] md:!h-[110px] md:!w-[165px]">
                <img
                    class="h-full m-auto cursor-pointer"
                    :src="slide.code" />
            </swiper-slide>
            <div class="white-overlay h-24 w-full absolute bottom-0 left-0 z-10 hidden md:block pointer-events-none"></div>
        </swiper>
    </div>
</template>

<style>
    .white-overlay {
        background: linear-gradient(
            180deg,
            rgba(251, 251, 251, 0) 0%,
            rgba(251, 251, 251, 0.015686) 1%,
            rgba(251, 251, 251, 0.054902) 3%,
            rgba(251, 251, 251, 0.117647) 7%,
            rgba(252, 252, 252, 0.2) 12%,
            rgba(252, 252, 252, 0.290196) 19%,
            rgba(253, 253, 253, 0.392157) 26%,
            rgba(253, 253, 253, 0.501961) 34%,
            rgba(253, 253, 253, 0.607843) 43%,
            rgba(254, 254, 254, 0.709804) 52%,
            rgba(254, 254, 254, 0.8) 62%,
            rgba(255, 255, 255, 0.882353) 72%,
            rgba(255, 255, 255, 0.945098) 81%,
            rgba(255, 255, 255, 0.984314) 91%,
            rgba(255, 255, 255, 1) 100%
        );
    }
</style>
