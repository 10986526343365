<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const accordionOpen = ref(props.active);

</script>

<template>
  <div>
    <h2>
      <button
:id="`accordion-header-${id}`"
        class="accordion-header flex items-center justify-between w-full py-[22px] border-t border-gray-100 text-left text-woom-black text-base font-mono md:text-2xl hover:text-woom-red"
        :class='[accordionOpen ? "text-woom-red" : ""]' :aria-expanded="accordionOpen"
        :aria-controls="`accordion-content-${id}`" @click.prevent="accordionOpen = !accordionOpen">
        <span>{{ title }}</span>
        <svg
class="fill-woom-black shrink-0 ml-8 accordion-icon" :class='[accordionOpen ? "fill-woom-red" : ""]' width="16"
          height="16" xmlns="http://www.w3.org/2000/svg">
          <rect
y="7" width="16" height="2" rx="1" class="transform origin-center transition duration-200 ease-out"
            :class="{ '!rotate-180': accordionOpen }" />
          <rect
y="7" width="16" height="2" rx="1"
            class="transform origin-center rotate-90 transition duration-200 ease-out"
            :class="{ '!rotate-180': accordionOpen }" />
        </svg>
      </button>
    </h2>
    <div
:id="`accordion-content-${id}`" :aria-labelledby="`accordion-header-${id}`"
      class="grid overflow-hidden transition-all duration-300 ease-in-out"
      :class="accordionOpen ? 'grid-rows-[1fr] opacity-100 h-full' : 'grid-rows-[0fr] opacity-0 h-0'">
      <div class="pb-3 overflow-hidden">
          <slot />
      </div>
    </div>
  </div>
</template>

<style>
.accordion-header:hover .accordion-icon {
  @apply fill-woom-red;
}
</style>
